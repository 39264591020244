<template>
  <v-card color="primary" tile>
    <!-- <v-toolbar light >
      <v-btn icon light @click.native="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Login Here!</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider> -->
    <v-container fluid>
      <!-- <v-btn large icon @click.native="close" color="white">
        <v-icon>mdi-close</v-icon>
      </v-btn> -->
      <v-row class="mt-6">
        <v-col
          cols="12"
          lg="4"
          offset-lg="4"
          md="6"
          offset-md="3"
          sm="8"
          offset-sm="2"
          class="text-center"
        >
          <v-toolbar light color="white" class="mt-5 rounded-t">
            <v-btn icon light @click.native="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $t("login_here") }}</v-toolbar-title>
          </v-toolbar>
          <v-card
            elevation="5"
            tile
            class="pa-10 rounded-b"
            light
            color="white"
          >
            <p align="center" class="mt-n5">
              <!-- <v-img src="/img/logo/logopajak.png" style="width: 100px"></v-img> -->
              <v-img src="/img/logo/logo-ung.png" style="width: 120px"></v-img>
            </p>
            <h2>{{ appNameNormal }}</h2>
            <small> {{ $t("login_desc") }} </small>
            <v-form ref="form" v-model="valid" lazy-validation class="mt-10">
              <!-- <v-text-field
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                required
                append-icon="mdi-email"
              ></v-text-field> -->
              <!-- <v-select
                v-model="jenis"
                :items="jenisItems"
                :rules="rules"
                dense
                item-value="id"
                item-text="jenis"
                label="Jenis User"
              ></v-select> -->
              <v-text-field
                v-model="username"
                :rules="rules"
                label="Username"
                required
                autofocus
                append-icon="mdi-account-key"
              ></v-text-field>
              <v-text-field
                v-model="passwordform"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="passwordRules"
                :type="showPassword ? 'text' : 'password'"
                label="Password"
                counter
                @click:append="showPassword = !showPassword"
              ></v-text-field>
              <div class="text-xs-center">
                <v-btn
                  color="secondary"
                  light
                  :disabled="!valid"
                  @click="submit"
                  :loading="loading"
                  class="black--text"
                  rounded
                >
                  {{ $t("login") }}
                  <v-icon right light>mdi-login-variant</v-icon>
                </v-btn>
              </div>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "login",
  data() {
    return {
      valid: true,
      loading: false,
      showPassword: false,
      // email: "198503302003121002",
      jenis: "3",
      username: "",
      passwordform: "",
      jenisItems: [
        { id: "1", jenis: "Operator" },
        { id: "2", jenis: "Dosen" },
        { id: "3", jenis: "Mahasiswa" },
        { id: "4", jenis: "Eksternal" },
      ],

      // Rules Form
      rules: [(v) => !!v || "Form ini wajib di isi"],
      passwordRules: [
        (v) => !!v || "Password required.",
        (v) => (v && v.length >= 5) || "Minimal 5 karakter",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /([a-zA-Z0-9_]{1,})(@)([a-zA-Z0-9_]{2,}).([a-zA-Z0-9_]{2,})+/.test(
            v
          ) || "E-mail must be valid",
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      setAlert: "alert/set",
      setAuth: "auth/set",
    }),
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$vloading.show();
        const config = {
          headers: {
            // Authorization: "Basic " + btoa("lalampa_app" + ":" + "lalampa@123"),
            "Content-Type": "application/x-www-form-urlencoded",
          },
        };
        const params = new URLSearchParams();
        params.append("username", this.username);
        params.append("password", this.passwordform);
        // params.append("user_level", this.jenis);
        this.axios
          .post("/api/auth/login", params, config)
          .then((response) => {
            this.loading = false;
            this.$vloading.hide();
            let { data } = response;
            // console.log(data);
            this.setAuth(data);
            this.setAlert({
              status: true,
              color: "success",
              text: "Login success",
            });
            this.close();
            // if (this.user.id > 0) {
            //   this.setAlert({
            //     status: true,
            //     color: "success",
            //     text: "Login success",
            //   });
            //   this.close();
            // } else {
            //   this.setAlert({
            //     status: true,
            //     color: "error",
            //     text: "Login failed",
            //   });
            // }
          })
          .catch((error) => {
            this.loading = false;
            this.$vloading.hide();
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.message,
            });
          });
      }
    },
    close() {
      this.$emit("closed", false);
      this.$router.replace({ name: "Home" });
    },
  },
};
</script>